<template>
  <a-modal
    title="绑定商家"
    width="50%"
    :visible="visible"
    :destroyOnClose="true"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div class="container">
        <a-row>
          <a-col :span="24" class="right">
            <div class="right-content">
              <!-- 筛选 -->
              <div class="table-page-search-wrapper">
                <a-form layout="inline">
                  <a-row :gutter="40">
                    <a-col :md="6" :sm="24">
                      <a-form-item label="关键词">
                        <a-input v-model="queryParam.keywords" allow-clear placeholder="关键词搜索" />
                      </a-form-item>
                    </a-col>
                    <!-- 按钮 -->
                    <a-col :md="10" :sm="24">
                      <a-button type="primary" style="margin-left: 8px" @click="initTableData">搜索</a-button>
                      <a-button
                        style="margin-left: 8px"
                        @click="() => ((queryParam = {}), (searchTime = []), initTableData())"
                        >重置</a-button
                      >
                    </a-col>
                  </a-row>
                </a-form>
              </div>
              <!-- 表格 -->
              <a-table
                :columns="columns"
                :data-source="data"
                :loading="loading"
                :pagination="false"
                rowKey="id"
                :row-selection="rowSelection"
              >
                <!-- 头像插槽 -->
                <span slot="avatar" slot-scope="text">
                  <img :src="text" alt="" />
                </span>
                <!-- 状态插槽 -->
                <span slot="delFlag" slot-scope="text, record">
                  <span v-if="record.delFlag == 2">
                    <a-tag color="red">已注销</a-tag>
                  </span>
                  <span v-else>
                    <a-switch
                      checked-children="正常"
                      un-checked-children="停用"
                      v-model="record.checked"
                      :disabled="true"
                    />
                  </span>
                </span>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { bindStore, listStore } from '@/api/modular/mallLiving/area'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '昵称',
    align: 'left',
    dataIndex: 'nickname',
    width: '180px',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
    width: '120px',
  },

  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'left',
    width: '120px',
  },

  {
    title: '头像',
    dataIndex: 'avatar',
    align: 'left',
    width: '100px',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '关注基数',
    dataIndex: 'followNumBase',
    align: 'left',
    width: '120px',
  },
  {
    title: '粉丝基数',
    dataIndex: 'fansNum',
    align: 'left',
    width: '120px',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    align: 'left',
    width: '100px',
    scopedSlots: {
      customRender: 'delFlag',
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '注销时间',
    width: '180px',
    dataIndex: 'cancellationTime',
    align: 'left',
  },
]
export default {
  name: 'chooseStore',
  data() {
    return {
      columns,
      areaId: '',
      visible: false, //modal框显示状态
      confirmLoading: false,
      data: [],
      searchTime: [],
      queryParam: {},

      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        type: 'radio',
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          console.log('selectedRowKeys, selectedRows', selectedRowKeys, selectedRows)
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onTimePickerChange(value, dateString) {
      console.log('Selected Time: ', value)
      console.log('Formatted Selected Time: ', dateString)
      this.queryParam.searchBeginTime = dateString[0]
      this.queryParam.searchEndTime = dateString[1]
    },
    init(record) {
      this.areaId = record.id
      this.visible = true
      this.initTableData()
    },
    handleSubmit() {
      if (!this.selectedRows.length) {
        return this.$message.info('请选择要绑定的商家！')
      }
      this.confirmLoading = true
      const { id: customerId } = this.selectedRows[0]
      bindStore({ id: this.areaId, customerId }).then((res) => {
        if (res.success) {
          this.$message.success('绑定商家成功！')
          this.$emit('ok')
          this.confirmLoading = false
          this.handleCancel()
        } else {
          setTimeout(() => {
            this.confirmLoading = false
          }, 600)
        }
      })
      this.handleCancel()
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
    },

    initTableData() {
      this.getList()
    },
    getList() {
      this.loading = true
      this.selectedRows = []
      this.selectedRowKeys = []
      listStore(this.queryParam)
        .then((res) => {
          if (res.success) {
            this.data = res.data
            this.data.forEach((item) => {
              item.checked = false
            })
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style scoped>
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}
/deep/ .ant-table-wrapper {
  overflow: auto;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
